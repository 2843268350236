import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../utility/utils"
import { currentYear } from "../../../../util/util"
const BusinessCashback = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  return (
    <Fragment>
      <div className="kuda-inner--heading kuda-disclaimer--section mt-0">
        <div className="kuda-section--inner">
          <div className="kuda-section--100 pb-0 inner-spotlight--heading">
            <h1 className="text-center text-xlbold color-primary text- title-bottom--spacing text-md-s">
              Cashback Program For Kuda Business Customers
            </h1>
            <h6 className=" text-center text-normal-md margin-bottom--spacer-sm text-xlbold color-black ">
              Terms & Conditions
            </h6>
          </div>
          <div className="kuda-section--100 bottom-spacer--lg">
            <p className="color-black title-bottom--spacing">
              Kuda Microfinance Bank Limited (“Kuda”) is offering new and
              existing Kuda Business customers (“Participant(s)”) the
              opportunity to earn cashback on airtime purchases and bill
              payments (the “Program”). The Program in its entirety is governed
              by these Terms and Conditions (in addition to the general terms
              and conditions that apply to your Kuda Business account) and by
              participating in the Program, you as a Participant agree to be
              bound by the terms specified herein as well as any subsequent
              changes or modifications made by Kuda to these Terms and
              Conditions.
            </p>
            <p className="color-black title-bottom--spacing">
              Please note the following preliminary points in relation to the
              Program:
            </p>
            <ul className="diclaimer-list text-normal color-black f-16 margin-bottom--spacer-sm">
              <li>
                The Program is only available to Kuda Business accounts, whether
                new or existing who elect to participate in the Program by
                opting in;
              </li>
              <li>
                To open a Kuda Business account, your business/company must have
                been incorporated with the Corporate Affairs Commission and you
                must provide all relevant KYC documents at onboarding.
              </li>
              <li>
                The Program is available for a defined period only, at Kuda’s
                discretion (“Offer Period”) and may be discontinued at anytime;
              </li>
              <li>
                Cashback is only applicable on airtime purchases and bill
                payments completed via the Kuda Business app (“Qualifying
                Transactions”), at a fixed rate as determined by Kuda;
              </li>
              <li>
                For standard Kuda Business accounts, the starting rate is 1.5%
                of the transaction amount. That is, whenever you buy airtime or
                pay a bill on Kuda Business, we’ll pay a 1.5% cash-back into
                your Kuda Business account. That’s 1.5% of the transaction
                amount. For example, if you buy airtime worth NGN1,000, we’ll
                send you NGN15.
              </li>
              <li>
                For our Open API customers (with Kuda Business Accounts), we
                offer them a 3% cashback on bills paid with our bill payment
                API. This means that whenever Open API customers (or their
                customers) buy airtime or pay bills, the Open API customer can
                earn up to 3% commission from us.
              </li>
              <li>
                For our Open API customers (with Kuda Business Accounts), we
                offer them a 3% cashback on bills paid with our bill payment
                API. This means that whenever Open API customers (or their
                customers) buy airtime or pay bills, the Open API customer can
                earn up to 3% commission from us.
              </li>
              <li>
                We reserve the right to change or modify this rate at any time
                during the Offer Period.
              </li>
              <li>
                Cashback is paid into your Kuda Business account within 24 hours
                of completing the above-stated transactions, and you will
                receive a notification when paid.
              </li>
            </ul>
          </div>
          <div className="kuda-section--100">
            <h6 className=" text-left text-xlbold color-primary title-bottom--spacing">
              General Terms:
            </h6>
            <div className="text-left kuda-disclaimer--wrap">
              <ul className="diclaimer-list text-normal color-black f-16 margin-bottom--spacer-sm">
                <li>
                  The claiming of a cashback by a Participant is subject to
                  verification by Kuda and may depend on any additional factors
                  set by Kuda. A cashback paid to a Participant in relation to
                  incorrect information or inaccurate KYC documents
                  automatically disqualifies the Participant from earning
                  additional cashbacks under the Program.
                </li>
                <li>
                  Kuda reserves the right to modify, amend or suspend at any
                  time, the Program, these Terms and Conditions and/or the
                  methods through which cashback is earned, with or without
                  notice to the Participant, and shall not be liable for any
                  such change or modification or actions. Termination,
                  modification or suspension may affect a Participant’s ability
                  to earn cashback. A Participant’s continued participation in
                  the Program after any such modifications or amendments would
                  constitute an acceptance of the updated Terms and Conditions.
                </li>
                <li>
                  Kuda also reserves the right to disqualify any Participant
                  from the Program at any time at our sole discretion, including
                  without limitation if such Participant fails to meet the
                  requirements, does not comply with any of these Terms and
                  Conditions or otherwise fails to comply with any applicable
                  laws.
                </li>
                <li>
                  A Participant hereby undertakes to indemnify Kuda, its group
                  member companies, affiliates and subsidiaries, together with
                  their respective employees, directors, officers and agents
                  from any and all claims, actions, demands, damages, losses,
                  liabilities, costs or expenses caused by, arising out of, or
                  in connection with the Participant’s participation in the
                  Program, including without limitation the use or misuse of the
                  cashback given under the Program.
                </li>
                <li>
                  In relation to the Program, a Participant undertakes not to:
                  <ul className="diclaimer-list text-normal color-black f-16 other-info list-style-type--roman">
                    <li>
                      resell, trade, barter or otherwise generate income by
                      providing access to the Program to others;
                    </li>
                    <li>
                      conduct any illegal activity or solicit the performance of
                      any illegal activity;
                    </li>
                    <li>
                      attempt to gain unauthorized access to the Program, other
                      accounts, computer systems, or networks connected to the
                      Program;
                    </li>
                    <li>
                      impersonate any person or otherwise misrepresent their
                      identity;
                    </li>
                    <li>
                      In relation to the Program, a Participant undertakes not
                      to:
                    </li>
                    <li>violate any applicable law.</li>
                  </ul>
                </li>
                <li>
                  Kuda reserves the right to prohibit a Participant from
                  participating in the Program or receiving a cashback, if it
                  determines that such a Participant is attempting to undermine
                  (or has undermined) the fairness, integrity or legitimate
                  operation of the Program in any way by (a) cheating, hacking,
                  deception, or any other unfair conduct; (b) using any
                  affiliate website, affiliate network properties or automated
                  systems to participate in the Program; (c) using multiple or
                  fake emails addresses or accounts, fictitious identities or
                  bots or other device or artifice to participate in the Program
                  or receive cashback.
                </li>
                <li>
                  The Participants understand and agree that Kuda shall not be
                  liable for any direct, indirect, incidental, special,
                  consequential, or exemplary damages, including, but not
                  limited to, damages for loss or use of data or other
                  intangible losses, resulting from (i) the use or the inability
                  to use the Program; (ii) transactions entered into through,
                  from, or as a result of the Program; (iii) unauthorised access
                  to or alteration of their data; (iv) statements or conduct of
                  any third party on or made in connection with the Program.
                </li>
                <li>
                  If one or more provisions of these Terms and Conditions are
                  void or invalid, this will not affect the validity of the
                  remaining conditions herein.
                </li>
                <li>
                  These Terms and Conditions shall be governed and construed in
                  accordance with the laws of the Federal Republic of Nigeria.
                </li>
              </ul>
            </div>
          </div>
          <p className="color-black title-bottom--spacing text-xlbold text-italic">
            February {currentYear()}
          </p>
        </div>
      </div>
    </Fragment>
  )
}
export default BusinessCashback
