import React from "react"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"
import BusinessCashback from "../../components/body/pages/incentive-program/businessCashback"

const BusinessCashbackRoute = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/incentive-program/business-cashback/"}
      title="Kuda Incentive Program - Business Cashback"
    />
    <BusinessCashback />
  </NoheaderFooter>
)
export default BusinessCashbackRoute
